<template>
  <admin-fillall-layout
    id="pick-album"
    ref="content"
    :fullscreen="isFullscreen"
  >
    <!-- 觸發下載的 DOM   -->
    <a
      ref="downloadNode"
      style="display: none"
      download
    />

    <!--  確認下載提示視窗  -->
    <default-dialog
      :show-dialog="showDownloadDialog"
      :center="true"
      :body-center="true"
      footer-align="center"
      :next-button-text="`\u00A0\u00A0${$t('button.yes')}\u00A0\u00A0`"
      :back-button-text="`\u00A0\u00A0${$t('button.no')}\u00A0\u00A0`"
      @close="showDownloadDialog = false"
      @next="downloadFile"
    >
      <template slot="main">
        <p class="dialog__txt--normal">
          {{ $t('page.history.dialog.save') }}
        </p>
      </template>
    </default-dialog>

    <!--  確認刪除提示視窗  -->
    <default-dialog
      :show-dialog="showDeleteDialog"
      :center="true"
      :body-center="true"
      footer-align="center"
      :next-button-text="`\u00A0\u00A0${$t('button.delete')}\u00A0\u00A0`"
      :back-button-text="`\u00A0\u00A0${$t('button.cancel')}\u00A0\u00A0`"
      @close="showDeleteDialog = false"
      @next="deleteFile"
    >
      <template slot="main">
        <h2 class="dialog__title">
          Are you sure?
        </h2>
        <p class="dialog__txt--normal">
          {{ $t('page.history.dialog.delete') }}
        </p>
      </template>
    </default-dialog>

    <!--   影片播放 -->
    <pick-album-player
      :title="currentVideoTitle"
      :is-show.sync="showVideo"
      :video-data="videoPlaylist"
      @change-video="changeVideo"
    />

    <back-button @back-click="$router.push('/history/list')" />
    <el-row class="history-header">
      <div v-if="source.items.length === 1">
        <h4 class="history-header__title">
          {{
            moment(source.items[0].mission_start_time)
              .format('YYYY/MM/DD HH:MM')
          }}
          &nbsp;&nbsp;&nbsp;&nbsp;
          {{ source.items[0].drone_id }}
        </h4>
      </div>
    </el-row>
    <el-row
      type="flex"
      justify="end"
      align="middle"
      class="manage-container"
    >
      <button
        v-show="isEdit"
        class="btn"
        @click="handleAll(true)"
      >
        <span class="manage-container__tool">{{ $t('map.title.drone.selectall') }}</span>
      </button>
      <button
        v-show="isEdit"
        class="btn"
        @click="beforeDelete"
      >
        <img
          class="manage-container__tool"
          src="/asserts/icon/history-delete-icon-normal.svg"
        >
      </button>
      <button
        v-show="isEdit"
        class="btn"
        @click="beforeDownload"
      >
        <img
          class="manage-container__tool"
          src="/asserts/icon/history-download-icon.svg"
        >
      </button>
      <el-button
        class="manage-container__btn"
        @click="manageHandler"
      >
        {{ isEdit ? 'Cancel' : 'Manage' }}
      </el-button>
    </el-row>
    <div class="media-section">
      <div
        v-for="record in source.items"
        :key="record.history_id"
      >
        <div
          v-if="isViewAll"
          class="split-title"
        >
          <span>
            {{
              moment(record.mission_start_time)
                .format('YYYY/MM/DD HH:MM')
            }}
          </span>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <span>
            {{ record.drone_id }}
          </span>
        </div>
        <div class="container">
          <div
            v-for="(video, idx) in record.videos"
            :key="`mv-${idx}`"
          >
            <div
              v-if="video.isDisplay"
              class="media is-video"
            >
              <el-checkbox
                v-show="isEdit"
                v-model="video.isSelect"
                class="media__checkbox"
              />
              <div @click="clickVideo('video', video, idx, record)">
                <img :src="video.thumbnail">
                <div class="playbutton" />
                <p class="duration">
                  {{ video.duration }}
                </p>
                <div class="desc">
                  <p class="title">
                    {{ video.type }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            v-for="(img, idx) in record.images"
            :key="`img-${idx}`"
          >
            <div
              v-if="img.isDisplay"
              class="media is-photo"
            >
              <el-checkbox
                v-show="isEdit"
                v-model="img.isSelect"
                class="media__checkbox"
              />
              <div>
                <img :src="img.content">
              </div>
              <div class="desc">
                <p class="title">
                  {{ img.type }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-row
      v-show="isEdit"
      class="selected-bar"
    >
      <span>
        {{ `${selectedCount.count} of ${allCount.count} item(s), ${selectedCount.capacity}MB` }}
      </span>
    </el-row>
  </admin-fillall-layout>
</template>

<script>
import BackButton from '@/components/Maps/BackButton.vue';
import { mapGetters } from 'vuex';
import moment from 'moment';
import DefaultDialog from '@/components/DefaultDialog.vue';
import PickAlbumPlayer from '@/views/admin/history/PickAlbumPlayer';
import historyAPI from '@/services/api/domain/group/histroy';
const defaultImgURL = '/asserts/icon/history-no-photo-icon.svg';

export default {
  name: 'PickAlbum',
  components: {
    BackButton,
    DefaultDialog,
    PickAlbumPlayer,
  },
  data() {
    return {
      source: {
        items: [],
        paging: {
          total: 0,
        },
      },
      filter: {
        page: 1,
        type: null,
        user: null,
        start: moment()
          .set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          }),
        end: moment()
          .set({
            hour: 23,
            minute: 59,
            second: 59,
            millisecond: 999,
          }),
      },
      currentVideoTitle: '',
      videoPlaylist: {
        currentUrl: '',
        idx: 0,
        historyData: {},
      },
      isEdit: false,
      showDeleteDialog: false,
      showDownloadDialog: false,
      showVideo: false,
      isFullscreen: false,
    };
  },
  computed: {
    isViewAll() {
      return this.source.items.length > 1;
    },
    selectedFile() {
      return this.source.items.reduce((accu, currentItem) => {
        const currFiles = {
          ...currentItem,
          videos: currentItem.videos.filter((video) => video.isSelect),
          images: currentItem.images.filter((img) => img.isSelect),
        };
        const isSelected = currFiles.videos.length > 0 || currFiles.images.length > 0;
        return isSelected ? accu.concat(currFiles) : accu;
      }, []);
    },
    allCount() {
      return this.countData(this.source.items);
    },
    selectedCount() {
      return this.countData(this.selectedFile);
    },
    ...mapGetters({
      group: 'user/group',
      groupId: 'user/groupId',
      isAdmin: 'user/isAdmin',
    }),
  },
  async mounted() {
    const { groupId, droneId, historyId } = this.$route.query;
    const loadData = await historyAPI.getHistoryFile(groupId, droneId, historyId);
    this.source.items = loadData.data.material.map((data) => ({
      ...data,
      videos: data.videos.map((video) => ({
        ...video,
        thumbnail: video.thumbnail || defaultImgURL,
        isSelect: false,
        isDisplay: true,
      })),
      images: data.images.map((image) => ({
        ...image,
        content: image.content || defaultImgURL,
        isSelect: false,
        isDisplay: true,
      })),
    }));
  },
  methods: {
    moment,
    countData(data) {
      let result = {
        count: 0,
        capacity: 0,
      };
      if (data.length > 0) {
        result = data.reduce((accu, currentItem) => ({
          capacity: accu.capacity
                      + this.getCapacityFromType(currentItem, 'images')
                      + this.getCapacityFromType(currentItem, 'videos'),
          count: accu.count + currentItem.videos.length + currentItem.images.length,
        }),
        result);
      }
      return result;
    },
    getCapacityFromType(record, type) {
      const file = record[type];
      return file.reduce((accu, currentFile) => accu + currentFile.size, 0);
    },
    handleAll(boolean) {
      this.source.items.forEach((item) => {
        [...item.videos, ...item.images].forEach((file) => {
          file.isSelect = boolean;
        });
      });
    },
    beforeDelete() {
      if (this.selectedFile.length) {
        this.showDeleteDialog = true;
      }
    },
    deleteFile() {
      this.showDeleteDialog = false;

      this.selectedFile.forEach(async (records) => {
        const deletedFile = [...records.videos, ...records.images];
        const deletedFileName = [];
        deletedFile.forEach((file) => {
          deletedFileName.push(file.fileName);
          file.isSelect = false;
        });
        try {
          const payload = { fileName: deletedFileName };
          await historyAPI.deleteHistoryFile(this.groupId, records.drone_id,
            records.history_id, payload);
          deletedFile.forEach((file) => { file.isDisplay = false; });
        } catch (e) { /**/ }
      });
    },
    beforeDownload() {
      if (this.selectedFile.length) {
        this.showDownloadDialog = true;
      }
    },
    downloadFile() {
      this.showDownloadDialog = false;

      this.selectedFile.forEach((records) => {
        [...records.videos, ...records.images].forEach((file) => {
          const node = this.$refs.downloadNode;
          node.href = file.content;
          node.download = file.fileName;
          node.click();
        });
      });
    },
    clickVideo(type, file, idx, historyData) {
      if (!this.isEdit && type === 'video') {
        this.playVideo(file, idx, historyData);
      }
    },
    manageHandler() {
      if (this.isEdit) {
        this.handleAll(false);
      }
      this.isEdit = !this.isEdit;
    },
    playVideo(fileData, idx, historyData) {
      this.currentVideoTitle = this.getVideoTitle(historyData.mission_start_time);
      this.showVideo = true;
      this.videoPlaylist = {
        currentUrl: fileData.content,
        idx,
        historyData,
      };
    },
    changeVideo(type) {
      const playlist = this.videoPlaylist;
      if (type === 'pre' && playlist.idx - 1 >= 0) {
        this.playVideo(playlist.historyData.videos[playlist.idx - 1],
          playlist.idx - 1, playlist.historyData);
      }
      if (type === 'next' && playlist.idx + 1 <= playlist.historyData.videos.length - 1) {
        this.playVideo(playlist.historyData.videos[playlist.idx + 1],
          playlist.idx + 1, playlist.historyData);
      }
    },
    /**
      * 建立影片標題字串
      * @example
      *  // return '2021-07-02 | 03:49:22'
      * getVideoTitle('2021-07-02T03:49:22.131Z')
      * */
    getVideoTitle(title) {
      return title.split('T').join(' | ').split('.')[0];
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
<style lang="scss">
#pick-album {
  .overlay.back {
    z-index: 3;
  }

  .font-normal {
    font-size: 1rem;
  }

  .dialog {
    &__title {
      font-size: 1.5rem;
      font-weight: 400;
    }

    &__txt {
      &--normal {
        font-size: 1rem;
      }
    }
  }

  .btn {
    border: none;
    background: transparent;

    &:hover {
      cursor: pointer;
      filter: brightness(.8);
      opacity: .8;
    }
  }

  .history-header {
    &__title {
      text-align: center;
      font-size: 1rem;
      font-weight: 500;
    }
  }

  .media-section {
    width: 100%;
    padding: 0 75px 75px;
  }

  .manage-container {
    padding: 4.5rem 4.5rem 0;

    &__tool {
      display: inline-block;
      margin: 0 1rem 0 0;
    }

    &__btn {
      background: #ebebeb;
      color: #1fa0e3;
    }
  }

  .container {
    display: flex;
    flex-wrap: wrap;
  }

  .media {
    margin: 1rem;
    width: 13rem;
    height: 14rem;
    display: flex;
    flex-direction: column;
    position: relative;

    img {
      width: 13rem;
      height: 13rem;
      object-fit: cover;
    }

    &__checkbox {
      position: absolute;
      right: .5rem;
      top: .5rem;
      .el-checkbox__inner{
        background-color: transparent;
        border-color: #FFF;
        border-width: 2px;
      }
      .el-checkbox__input.is-checked .el-checkbox__inner,
      .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #409EFF !important;
        border-color: #409EFF !important;
      }

      span.el-checkbox__inner::after {
        border: 1px solid #fff;
        border-left: 0;
        border-top: 0;
      }
    }
  }

  .media a {
    width: 150px;
    height: 150px;
  }

  .title {
    margin: .25rem 0;
    text-align: right;
  }

  .is-photo {
    img {
      cursor: pointer;
      opacity: 0.8;
    }

    img:hover {
      opacity: 1;
    }
  }

  .is-video {
    img {
      opacity: 0.8;
    }

    .playbutton {
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-image: url('/asserts/icon/video-play.png');
      width: 4.4rem;
      height: 4.4rem;
      background-size: cover;
      cursor: pointer;
      opacity: 0.8;
    }

    .playbutton:hover {
      opacity: 1;
    }

    .duration {
      position: absolute;
      top: 75%;
      right: .25rem;
      transform: translateY(-50%);
      color: white;
      font-weight: 200;
      opacity: 0.8;
    }
  }

  .split-title {
    margin-top: 15px;
    border-bottom: 1px solid #4f9fdd;
    margin-bottom: 15px;
    padding-bottom: .25rem;
  }

  .selected-bar {
    position: fixed;
    left: 64px;
    bottom: 0;
    padding: 1rem 0;
    text-align: center;
    width: calc(100vw - 64px);
    color: #161616;
    font-size: .75rem;
    background: #FFF;
    border-top: 1px solid #dedede;
  }
}
</style>
