<template>
  <main
    v-if="isShow"
    class="player"
    @click="closeVideo"
  >
    <section
      class="video"
      @mouseover="toggleBtn(true)"
      @mouseleave="toggleBtn(false)"
    >
      <div
        :class="{
          'player__pre': true,
          'player__pre--disabled': !hasPreVideo || !isHover}"
        @click="$emit('change-video', 'pre')"
      >
        <i class="el-icon-arrow-left" />
      </div>
      <p class="video__title">
        {{ title }}
      </p>
      <video
        controls
        class="video__player"
        :src="videoData.currentUrl"
      />
      <div
        :class="{
          'player__next': true,
          'player__next--disabled': !hasNextVideo || !isHover}"
        @click="$emit('change-video', 'next')"
      >
        <i class="el-icon-arrow-right" />
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: 'PickAlbumPlayer',
  props: {
    videoData: {
      required: false,
      type: Object,
      default: () => ({
        currentUrl: '',
        idx: 0,
        historyData: { videos: [] },
      }),
    },
    isShow: {
      required: false,
      type: Boolean,
      default: false,
    },
    title: {
      required: false,
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isHover: false,
      hoverHandler: null,
    };
  },
  computed: {
    hasPreVideo() {
      return this.videoData.idx - 1 >= 0;
    },
    hasNextVideo() {
      return this.videoData.idx + 1 <= this.videoData.historyData.videos.length - 1;
    },
  },
  methods: {
    isTarget(e) {
      return e.target === e.currentTarget;
    },
    closeVideo($event) {
      if (!this.isTarget($event)) {
        return;
      }
      this.$emit('update:isShow', false);
    },
    toggleBtn(isShowBtn) {
      clearTimeout(this.hoverHandler);
      this.hoverHandler = setTimeout(() => {
        this.isHover = isShowBtn;
      }, 500);
    },
  },
};
</script>

<style scoped lang="scss">
.player {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 6;
  background-color: rgba(0, 0, 0, .8);
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  &__pre, &__next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: .25rem;
    color: #b3b3b3;
    background: #323232;
    margin: 1rem;
    font-size: 2rem;
    z-index: 1;

    &:hover {
      cursor: pointer;
      opacity: .8;
    }

    &--disabled {
      visibility: hidden;
    }
  }

  &__pre {
    left: 0;
  }

  &__next {
    right: 0;
  }
}

.video {
  width: 60%;
  position: relative;

  &__title {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.1rem;
    color: #FFFFFF;
    z-index: 1;
  }

  &__player {
    width: 100%;
    height: auto;
  }
}
</style>
